.mass-search-offers-nav {
  width: 100%;
  .mass-search-offers-nav_link {
    padding: 6px 12px;
    color: var(--dark);
    white-space: nowrap;
    border: 1px solid var(--main-blue-gray-3);
    & :global(.anticon) {
      margin-right: 4px;
    }
    &:active,
    &:hover {
      color: var(--white);
      background-color: var(--main-orange-8);
    }
    &.active {
      font-weight: 500;
      color: var(--white);
      background-color: var(--corporate-orange);
    }
  }
  .mass-search-offers-nav_link .skeleton:global(.ant-skeleton.ant-skeleton-element),
  .skeleton:global(.ant-skeleton.ant-skeleton-element .ant-skeleton-input) {
    width: 42px;
    min-width: 42px;
    height: 20px;
  }
}
